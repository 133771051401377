@import "~bootstrap/dist/css/bootstrap.css";
@import 'src/theme/variables.scss';
@import 'src/theme/mixins.scss';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: 'montserrat-regular';
    src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'montserrat-medium';
    src: url('./assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'montserrat-semibold';
    src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'montserrat-bold';
    src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Edwardian Script ITC';
    src: url('./assets/fonts/Edwardian\ Script\ ITC\ Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html, body {
    font-family: 'montserrat-regular', sans-serif;
    font-size: 16px;
    scroll-behavior: smooth;
}

.container{
    max-width: 1410px;
    margin: 0 auto;
}

.detail{
    margin-top: 25px;
}

input{
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'montserrat-medium', sans-serif;
}

div#bbox-root {
    display: flex;
    justify-content: center;
}

input[type=number] {
    -moz-appearance:textfield !important;
    appearance: none !important;
    -webkit-appearance: none !important;

}

.no-data-found {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}


.mobile-select-block {
    background: no-repeat;
    padding: 0px;
    border: 0;
    .mobile-select {
        height: 58px;
        opacity: 1;
        box-shadow: none !important;
        border: 1px solid #C5C5C5;
        background-image: none;
        padding: 10px;
        border-right: 0;
    }
}

.mobile-input {
    border-left: 0 !important;
}

.pointer {
    cursor: pointer !important;
}

.notification-block {
    margin: 32px 0;
    max-height: 316px;
    overflow: auto;
    .list-item {
        display: flex;
        // align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 12px 0;
        .list-item-avatar {
            margin-right: 16px;
            padding-top: 6px;
            .iconDiv {
                background-color: #939598;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        
    }
}

.mt-30{
    margin-top: 30px;
}
.mb-30{
    margin-bottom: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-16{
    margin-right: 16px !important;
}

.ml-16{
    margin-left: 16px !important;
}

.disable{
    display: none !important;
}
.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.paragraphStyling p {
    margin-bottom: 5px !important;  
  }

  #page-container {
    position: relative !important;
    background-color: transparent !important;
}
// custom tooltip start
.more-info-tooltip {
    position: initial !important;
    display: inline-block;
    margin-bottom: 15px;
    img {
        cursor: pointer;
        position: relative;
        top: -1px;
        margin-left:1px !important;
    }
    .more-info-tooltiptext {
        display: none;
        background-color: white;
        position: absolute;
        z-index: 100000000000000020;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 16px;
        // padding: 15px;
        color: #3A3A3A;
        min-width: 650px;
        width: auto;
        max-width: 650px;
        box-shadow: 1px 5px 7px #c4c4c4;
        top: auto !important;
        text-align: left; /* Align text to the left */
        p {
          margin: 0;
        }
      }
    //   .more-info-text-inner-container{
    //     padding: 0 15px;
    //     padding-bottom: 15px;
    //   }
    
}
.more-info-text-inner-container::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    border-radius: 10px;
  }
  
  .more-info-text-inner-container::-webkit-scrollbar-thumb {
    background-color: #8cafdc;
    border: 1px solid #275084;
    /* Set the thumb color */
    // border-radius: 10px;
  }
  
  .more-info-text-inner-container::-webkit-scrollbar-track {
    background-color: white; /* Set the background color of the scrollbar track */
  }

 

// .more-info-tooltip:hover .more-info-tooltiptext {
//     // visibility: visible;
//    // display:inline-block;
//    display: block;
// }

.titleDesc{
    position: relative;
    // display: inline-block;
    
}

.article-description {
    display: none;
    background-color: #FFEACB;
    position: absolute;
    z-index: 100000000000000020;
    border-radius: 10px;
    font-size: 16px;
    padding: 15px;
    color: #3A3A3A;
    // min-width: 350px;
    width: auto;
    // max-width: 250px;
    // max-height: 350px; /* Set a maximum height */
    max-height: 104px;
    overflow-y: auto; /* Add vertical scrollbar when needed */
    box-shadow: 1px 5px 7px #c4c4c4;
    // top: auto !important;
    top:-5px;
    text-align: left; /* Align text to the left */
    p {
      margin: 0;
    }
}

.partener-tooltip{
    display: none;
    background-color: #FFEACB;
    position: absolute;
    z-index: 100000000000000020;
    border-radius: 10px;
    font-size: 16px;
    padding: 15px;
    color: #3A3A3A;
    width: auto;
    min-width: 450px;
    max-width: 250px;
    max-height: 350px; /* Set a maximum height */
    overflow-y: auto; /* Add vertical scrollbar when needed */
    box-shadow: 1px 5px 7px #c4c4c4;
    top: auto !important;
    text-align: left; /* Align text to the left */
    p {
      margin: 0;
    }
  }
  
  .partener-desc:hover .partener-tooltip {
    display:block !important;
    }
.partener-tooltip::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
    border-radius: 10px;
  }
  
  .partener-tooltip::-webkit-scrollbar-thumb {
    background-color: #f0be75;
    /* Set the thumb color */
    border-radius: 10px;
  }
  
  .partener-tooltip::-webkit-scrollbar-track {
    background-color: #FFEACB; /* Set the background color of the scrollbar track */
  }


// .titleDesc:hover #resourcesSlider .owl-theme .owl-dots{
//     display: none;
// }

// #resourcesSlider .article-description:hover ~ .owl-theme .owl-dots {
//     display: none;
//   }
.article-description::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
    border-radius: 10px;
  }
  
  .article-description::-webkit-scrollbar-thumb {
    background-color: #f0be75;
    /* Set the thumb color */
    border-radius: 10px;
  }
  
  .article-description::-webkit-scrollbar-track {
    background-color: #FFEACB; /* Set the background color of the scrollbar track */
  }

.more-info-tooltip:hover .more-info-tooltiptext {
    // visibility: visible;
   // display:inline-block;
   display: block;
}

.overflow-right{
    left:0px;
}

.form-description {
    position: relative; 
}
.form-description-tooltip{
    display: none;
    background-color: #FFEACB;
    position: absolute;
    z-index: 100000000000000020;
    border-radius: 10px;
    font-size: 16px;
    padding: 15px;
    color: #3A3A3A;
    width: auto;
    max-height: 119px;
    overflow-y: auto; /* Add vertical scrollbar when needed */
    box-shadow: 1px 5px 7px #c4c4c4;
    top:-5px;
    text-align: left; /* Align text to the left */
    p {
      margin: 0;
    }

}

@include respond-below(xs){
    .form-description:hover .form-description-tooltip {
        display: block !important;
    }
}

// end

// custom radio button created start
.radio-button-block {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
       // line-height: 20px;
        line-height:23px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
        border: 1px solid #FBB040;
        border-radius: 100%;
        background: #fff;
    }
    [type=radio]:not(:checked) + label:before {
        border: 1px solid #6D737B;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 14px;
        height: 14px;
        background: #FBB040;
        position: absolute;
        top: 5.5px;
        left: 5.5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    .radio-label {
        font-size: 18px;
        color: #1E1E1E;
        display: inline-block;
        padding-left: 11px;
        position: relative;
        top: 3px;
    }
}    

// end

// custom checkbox start
.checkbox-button-block {
    .control {
        display: block;
        position: relative;
        padding-left: 41px;
        margin-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;
        font-size: 16px;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 2px;
        bottom: 0;
        margin: auto 0;
        left: 0;
        height: 26px;
        width: 26px;
        background: #fff;
        border: 1px solid #6D737B;
        border-radius: 5px;
    }
    // .control:hover input ~ .control_indicator,
    // .control input:focus ~ .control_indicator 
    // {
    //     background: #cccccc;
    // }
    
    .control input:checked ~ .control_indicator {
        background: #FBB040;
          border-color: #FBB040;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        // background: #0e6647;
    }
    .control input:disabled ~ .control_indicator {
        // background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 10px;
        top: 4px;
        width: 4px;
        height: 10px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }
    .control-checkbox .control_indicator::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 4.5rem;
        height: 4.5rem;
        margin-left: -1.3rem;
        margin-top: -1.3rem;
        // background: #2aa1c0;
        border-radius: 3rem;
        opacity: 0.6;
        z-index: 99999;
        transform: scale(0);
    }
}


// custom checkbox end

#uploadModal, #uploadModal2{
    ngx-file-drop{
        .ngx-file-drop__drop-zone, .ngx-file-drop__content{
            height: 100%;
        }
        .ngx-file-drop__drop-zone{
            border: 0;
        }
    }
}
#resourcesSlider,#resourcesSlider2, #willFileSlider, #funeralFileSlider, #healthFileSlider, #sharedDocSlider{
    .owl-theme{
        position: relative;
        .owl-dots{
            bottom: -13%;
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;
            .owl-dot{
                border: 1px solid #3A3A3A;
                width: 14px;
                height: 14px;
                border-radius: 100px;
                margin: 0 8px;
            }
            .owl-dot.active{
                background: map-get($colors, orange-color);
                border: 1px solid map-get($colors, orange-color);
            }
            .owl-dot span, .owl-dot.active span, .owl-dot:hover span{
                display: none;
            }        
        }
        .owl-nav{
            position: absolute;
            top: 37%;
            width: 100%;
            .owl-prev{
                left: -4%;
                position: absolute;
            }
            .owl-next{
                right: -4%;
                position: absolute;
            }
        }
        .owl-nav .owl-prev, .owl-nav .owl-next{
            width: 50px;
            height: 50px;
            // background: #ffffff70;
            background: #9fa1a270;
            border-radius: 999px;
            opacity: 1.2;
            top: 32%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .owl-nav .owl-prev img, .owl-nav .owl-next img {
            width: 30px;
            filter: brightness(77%) contrast(169%);
        }

    }
}


#resourcesSlider2 .owl-theme .owl-dots{
    bottom: -34% !important;
}
#willFileSlider .owl-carousel .owl-item img, #funeralFileSlider .owl-carousel .owl-item img, #healthFileSlider .owl-carousel .owl-item img, #sharedDocSlider .owl-carousel .owl-item img{
    width: auto;
}

#willFileSlider .owl-dots, #funeralFileSlider .owl-dots, #healthFileSlider .owl-dots{
    bottom: -14% !important;
}

#sharedDocSlider .owl-dots{
    bottom: 9% !important;
}

.desktop-visibility {
    display: block !important;
}
.mobile-visibility {
    display: none !important;
}

.desktop-visibility-flex {
    display: flex !important;
}
.mobile-visibility-flex {
    display: none !important;
}

.login-header {
    .mobile-header {
        .icon-wrap {
            visibility: hidden;
        }
    }
}

#menuModal {
    background: transparent;
    .modal-dialog {
        margin: 1px;
        .modal-content {
            height: calc(100vh - 2px);
            .modal-header {
                border: none;
                box-shadow: 0px 3px 6px #0000001a;
                min-height: 70px;
                display: flex;
                justify-content: center;
                position: relative;
                .left-side {
                    width: 53px;
                    visibility: hidden;
                }
                .logo-wrap {
                    img {
                        width: 165px;
                    }
                }
                .btn-close {
                    position: absolute;
                    left: 12px;
                    width: 18px;
                    height: auto;
                }
            }
            .modal-body {
                padding: 0 9px;
                margin-top: 28px;
                .nav-col {
                    nav {
                        background: #F2F2F2;
                        border-radius: 10px;
                        background: none;
                        a {
                            font-size: 20px;
                            letter-spacing: 0px;
                            color: #1E1E1E;
                            font-family: "montserrat-regular", sans-serif;
                            height: 55px;
                            padding: 14px 20px;
                            cursor: pointer;
                            text-decoration: none;
                            background: map-get($colors, dark-white-color);
                            margin-bottom: 10px;
                            border-radius: 7px;
                        }
                        a:active {
                            background: #FBB040;
                            border: 1px solid #FBB040;
                            border-radius: 7px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    
}

.ng-select.ng-select-single .ng-select-container {
    // height: 58px;
    height: auto;
    max-height: 100px;
    min-height: 58px;
    .ng-value-container .ng-input {
        top: 15px;
    }
    .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        font-size: 18px;
            white-space: normal !important;
    }
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: normal !important;
    height: auto !important;
    max-height: 100px !important;
    min-height: 58px !important;
    padding-top: 13px  !important;
    padding-left: 8px !important;

    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
    height: auto !important;
    min-height: 49px;
    max-height: 89px !important;
    padding-top: 13px  !important;
    padding-left: 8px !important;

    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

#navModal {
    background: #fff;
    .modal-dialog {
        margin: 1px;
        .modal-content {
            height: calc(100vh - 2px);
            .modal-header {
                border: none;
                box-shadow: 0px 3px 6px #0000001a;
                min-height: 70px;
                .left-side {
                    display: flex;
                    width: calc(100% - 76px);
                    align-items: center;
                    img{
                        margin-left: 10px;
                    }
                    .name-block {
                        color: map-get($colors, black-color);
                        font-size: 13px;
                        margin-left: 25px;
                        display: flex;
                        flex-direction: column;
                        .name {
                            font-size: 20px;
                            font-family: "montserrat-bold", sans-serif;
                        }
                    }
                }
                .right-side {
                    .notify {
                        background: map-get($colors, orange-color);
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .logo-wrap {
                    img {
                        width: 165px;
                    }
                }
                .btn-close {
                    width: 53px;
                    margin-left: 0;
                }
            }
            .modal-body {
                padding: 0 9px;
                margin-top: 28px;
                .nav-col {
                    nav {
                        background: #F2F2F2;
                        border-radius: 10px;
                        background: none;
                        a {
                            font-size: 20px;
                            letter-spacing: 0px;
                            color: map-get($colors, black-color);
                            font-family: "montserrat-regular", sans-serif;
                            height: 55px;
                            padding: 14px 20px;
                            cursor: pointer;
                            text-decoration: none;
                            background: map-get($colors, dark-white-color);
                            margin-bottom: 10px;
                            border-radius: 7px;
                        }
                        a:active {
                            background: #FBB040;
                            border: 1px solid #FBB040;
                            border-radius: 7px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    
}


#resourcesSlider .owl-carousel .owl-item{
    width: 345px !important;
    margin-left: auto;
    margin-right: auto !important;
}
#resourcesSlider .owl-carousel .owl-stage{
    display: flex;
    // justify-content: center;
}



.BBFormFieldContainer{
    margin-left: 0 !important;
}

//Dropdown tooltip
.tooltipSelectWrap .ng-dropdown-panel .scroll-host{
    position: revert;
}

//Home - Landing

#landing-carousel .owl-theme .owl-dots .owl-dot.active span {
    background: #FBB040 !important;
}

@include respond-below(xxl){
    html, body {
        font-size: 12px;
    }
    .container{
        max-width: 1181px;
    }
    .more-info-tooltiptext {
        white-space: normal !important ;
    }   
}

@include respond-below(hxl){
    #resourcesSlider .owl-theme .owl-nav, #resourcesSlider2 .owl-theme .owl-nav{
        width: 90%;
        right: 4.5%;
    }
    #resourcesSlider .owl-carousel .owl-item{
        width: 100% !important;
    }
}

@include respond-below(xl){
    .partener-tooltip{
        min-width: auto;
    }
}

@include respond-below(lg){
    .more-info-tooltiptext{
        min-width: 300px !important;
    }
    
    .detail{
        margin-top: 10px;
    }
    .detail .login-box{
        padding: 20px !important;
        margin: 22px auto 0 !important;
    }
    .detail .title{
        font-size: 24px !important;
    }
    .detail .nav-col{
        padding-left: 30px;
    }
    .detail {
        padding: 20px 20px !important;
    }
    #resourcesSlider .owl-theme .owl-nav {
        display: none;
    }
    .mobile-select-block {
        .mobile-select {
            height: 40px;
            border-radius: 10px 0 0 10px;
        }
    }
    .container-mobile {
        padding: 0 15px !important;
    }
    .desktop-visibility {
        display: none !important;
    }
    .mobile-visibility {
        display: block !important;
    }
    .desktop-visibility-flex {
        display: none !important;
    }
    .mobile-visibility-flex {
        display: flex !important;
    }

    #resourcesSlider .owl-carousel .owl-stage{
        justify-content: flex-start !important;
    }

    #resourcesSlider2 .owl-theme .owl-nav
     {
        display: none;
    }
    #resourcesSlider2 .owl-theme .owl-dots {
        bottom: -25% !important;
    }
    .titleDesc:hover .article-description {
        display:block !important;
    }
    
}

@include respond-below(sm){

    #navModal .modal-dialog .modal-content .modal-body .nav-col nav a {
        font-size: 17px;
    }
    .container-mobile {
        padding: 0 15px !important;
    }
    html, body {
        font-size: 12px;
    }
    .detail {
        padding: 20px 0px !important;
        .login-box {
            button {
                height: 40px !important;
            }
        }
    }
    .detail .login-box{
        width: auto !important;
    }
    .p-0 {
        padding: 0 !important;
    }
    .detail .p-0{
        padding: 0px !important;
    }

    .more-info-tooltip{
        position: initial !important;
    }

    .more-info-tooltiptext{
        // right: auto !important;
        // left: 21px;
        // min-width: auto !important;
        min-width: 300px !important;
        right: 0 !important;
        left: 0;
       // min-width: auto !important;
        box-shadow: 1px 5px 7px #c4c4c4;
        top: auto !important;
    }

    #resourcesSlider2 .owl-theme .owl-nav
     {
        display: none;
    }

    #resourcesSlider2 .owl-theme .owl-dots {
        bottom: -12% !important;
    }

}

@include respond-between(lg, xl){
// @media (min-width: 991px) and (max-width: 1200px) {
    .container{
        max-width: 90em !important;
    }

    .aboutUs {
        font-size: 16px;
    }
    .owl-theme .owl-nav{
        display: none !important;
    }

    .header .container .links ul li {
        font-size: 16px !important;
    }
    .notification {
        width: 37px !important;
        height: 37px !important;
    }

    .header .user-img {
        width: 37px !important;
        height: 37px !important;
    }

    .header .user-detail a.userName {
        font-size: 16px !important;
    }
    

}

@media screen and  (max-device-width: 950px) and (orientation : landscape){
    #navModal .modal-dialog .modal-content {
                height: 100%;
            }
}

.tooltipWrap  ::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    border-radius: 10px;
  
}
  
  .tooltipWrap ::-webkit-scrollbar-thumb {
    background-color: #8cafdc;
    border: 1px solid #275084;
}
  
  
  .tooltipWrap ::-webkit-scrollbar-track {
    background-color: white; /* Set the background color of the scrollbar track */
  
}

.tooltipWrapSelecdispt ::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    border-radius: 10px;
}
.tooltipWrapSelecdispt ::-webkit-scrollbar-thumb {
    background-color: #8cafdc;
    border: 1px solid #275084;
}
  
  
.tooltipWrapSelecdispt ::-webkit-scrollbar-track {
    background-color: white; /* Set the background color of the scrollbar track */
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 343px !important;
}






